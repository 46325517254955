import React, {useState} from "react"
import { Link, graphql } from "gatsby"

// import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import Filters from "../components/filters"
import {Mobile, Desktop} from '../components/sizeWrappers'
import { useMediaQuery } from 'react-responsive'


const BlogIndex = ({ data, location }) => {
  const isDesktop = useMediaQuery({ minWidth: 768  })
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  // const tagsState = posts.reduce((array, post) => {
  //   if (post.node.frontmatter.tags) {
  //     for (const tag of post.node.frontmatter.tags) {
  //       if (!array.includes({ name: tag, selected: false })) {
  //         array.push({name: tag, selected: false}) 
  //         }
  //     } 
  //   }
  //   return array 
  // }, [])
  // const [toggledTags, setToggledTags] = useState(tagsState)
  // const onClick = (name) => {
  //   const index = toggledTags.findIndex(object => object.name === name)
  //   toggledTags[index] = { name: name, selected: !toggledTags[index]["selected"]}
  //   setToggledTags(toggledTags)
  // }
  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      {/* <Filters tags={toggledTags} onClick={onClick}/> */}
      {/* <Bio /> */}
      {/* <h3>Posts:</h3> */}
      <ul style={{ listStylePosition: 'inside' }}>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <Link to={node.fields.slug} style={{ color: 'inherit', textDecoration: 'inherit'}}>
            <div style={{borderStyle: 'solid', borderWidth: 'thin', marginTop: '1em', display: 'flex', flexDirection: isDesktop ? 'row': 'column', padding: '1em'}}>
              <img style={{width: '200px', height: 'auto'}} src={node.frontmatter.image}/>
              <div style={{display: 'flex', flexDirection: 'column', padding: '1em'}}>
              <p>{title}</p>
              <p>{node.excerpt}</p>
              </div>
              
            </div>

            
            </Link>
          )
        })}
      </ul>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
            readingTime {
              text
            }
          }
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            image
          }
        }
      }
    }
  }
`
