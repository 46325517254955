import React from 'react'
import { Icon, Label } from 'semantic-ui-react'

const Filters = ({tags, onClick}) => {
    // I started to implement Filters in Sept 2021 but never finished
    return (
        <Label.Group>
        {tags.map((tag)=> (
            <Label as='a' color={tag.selected ? 'blue' : 'gray'} onClick={() => onClick(tag.name)}>{tag.name}</Label>
            // {tag.selected && <Icon name='close'/>}
        ))}
    </Label.Group>
)
    }

export default Filters